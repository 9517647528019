export default {
  accreditedTestModal: {
    abort: 'Cancel',
    confirm: 'Finish editing and add analysis',
    description: 'To add this analysis you must first fill in the "Batch" field for ',
    lot: 'Sample batch "{0}":',
    next: 'Edit and move on to the next sample',
    samples: 'no sample | another {n} sample | other {n} samples',
    title: 'Warning!',
    warning: 'Attention, accredited analysis',
  },
  analyses: {
    create: {
      abortCreate: 'Cancel',
      accreditedTest: 'Accredited test',
      analysisTypeFilter: 'Filter by type',
      brand: 'Brand',
      brandCode: 'Brand code',
      class: 'Class',
      classType: 'Class type',
      code: 'Item code',
      commodityCategory: 'Product code description',
      commodityCode: 'Product category',
      department: 'Department',
      departmentCode: 'Department code',
      description: 'Item Description',
      dynamicClass: 'Dynamic class',
      expirationDate: 'End of validity of item',
      extraDescription: 'Additional item description',
      family: 'Family',
      familyCode: 'Family code',
      fixedClass: 'Fixed class',
      formHeader: 'Create Analysis/Class',
      maxAnalysesNumber: 'Maximum number of analyses',
      measure: 'UoM description',
      measureCode: 'UoM code',
      measureUnitCode: 'Unit of Measurement',
      method: 'Method description',
      methodCode: 'Method code',
      nature: 'Nature code description',
      natureCode: 'Nature code',
      removeAnalysis: 'Delete',
      searchAnalyses: 'Search for analysis',
      singleAnalysis: 'Analysis',
      statisticCategory: 'Statistical category description',
      statisticCode: 'Statistical category code',
      submitButton: 'Submit',
      success: 'Analysis created successfully',
    },
    index: {
      createAnalysis: 'Create Analysis/Class',
    },
    update: {
      addToClass1: 'Add to a class',
      addToClass2: 'Add to class',
      formHeader: 'Edit Analysis',
      success: 'Successfully updated analysis',
    },
  },
  collapse: {
    CHIM_approved: 'Chemical Test Report',
    CHIM_draft: 'Chem. report preview',
    CHIM_toBeApproved: 'Chem. report to be approved',
    MICR_approved: 'Microbiological Test Report',
    MICR_draft: 'Micro. report preview',
    Preview_draft: 'Excel preview',
  },
  common: {
    noPermissonRoute: 'You don\'t have the rights to access this page!',
  },
  customers: {
    create: {
      abortCreate: 'Cancel',
      address: 'Address',
      agent: 'Agent',
      canViewRawData: 'View raw data',
      certifiedEmail: 'Email',
      city: 'City',
      createCustomer: 'Create Company',
      district: 'Province',
      fiscalCode: 'Tax Id code',
      internalReference: 'Onda Id',
      isEnabled: 'Active',
      mobile: 'Mobile',
      name: 'Name',
      phoneNumber: 'Phone',
      sdi: 'SDI',
      success: 'Successfully created company',
      vat: 'VAT number',
      zip: 'Postal code',
    },
    update: {
      success: 'Company updated successfully',
      updateCustomer: 'Edit company',
    },
  },
  dynamicAnalysesModal: {
    abort: 'Cancel',
    confirm: 'Finish editing and add analysis',
    wrongNumber: 'Select {n} analyses to proceed!',
  },
  enums: {
    accreditedTest: {
      no: 'No',
      partially: 'Contains accredited tests',
      yes: 'Yes',
    },
    analysisStatuses: {
      accepted: 'Accepted',
      completed: 'Completed',
      deleted: 'Deleted',
      inprogress: 'In progress',
      none: 'Created',
      pending: 'Pending',
      rejected: 'Refused',
      suspended: 'Suspended',
    },
    commodityCategory: {
      chemical: 'Chemical',
      microbiological: 'Microbiological',
      vitro: 'Vitro',
    },
    commons: {
      no: 'No',
      yes: 'Yes',
    },
    complifeLaboratory: {
      CHIM: 'Chemical',
      MICR: 'Microbiological',
    },
    enabledStatus: {
      disabled: 'Disabled',
      enabled: 'Enabled',
    },
    functions: {
      administration: 'Administration',
      logistics: 'Logistics',
      production: 'Manufacturing',
      purOff: 'Purchasing Department',
      qa: 'Quality Assurance',
      qc: 'Quality Control',
      rd: 'Research and Development',
      undefined: '-',
    },
    newsStatuses: {
      published: 'Published',
      unpublished: 'Draft',
    },
    offerType: {
      brackets: 'Volume discount',
      list: 'Price list',
      null: 'None',
      quantity: 'Subscription Rate',
    },
    orderLocked: {
      acceptance: 'Blocked analyses',
      none: 'No block',
      report: 'Blocked test report',
    },
    orderStatuses: {
      accepted: 'Accepted',
      canceled: 'Deleted',
      completed: 'Completed',
      draft: 'Draft',
      draftCompleted: 'Completed draft',
      inProgress: 'In progress',
      rejected: 'Refused',
      sent: 'Sent',
    },
    sampleStatuses: {
      accepted: 'Accepted',
      canceled: 'Deleted',
      concluded: 'Concluded',
      created: 'Created',
      draft: 'Draft',
      inprogress: 'In progress',
      pending: 'Pending',
      rejected: 'Refused',
      suspended: 'Suspended',
    },
  },
  filters: {
    accreditedTest: 'Accredited test',
    actions: 'Actions',
    active: 'Enabled',
    activeInactive: 'Enabled / Disabled',
    additionalDescription: 'Additional description',
    address: 'Address',
    analysisCode: 'Analysis code',
    analysisCount: 'Total Analyses',
    applyFilter: 'Apply filters',
    canViewRawData: 'Raw data',
    childrenCount: 'Analyses Number',
    city: 'City',
    class: 'Class',
    clearFilter: 'Empty',
    code: 'Item code',
    comment: 'Comment',
    commodityCategory: 'Product category',
    companyName: 'Company name',
    complifeLaboratory: 'Laboratory',
    countryCode: 'Language code',
    createdAt: 'Date',
    customDescription: 'Description',
    customer: 'Company',
    customerCode: 'Customer code',
    customerName: 'Company name',
    customerOrderNumber: 'Customer order number',
    customerProductName: 'Customer sample code',
    date: 'Date',
    description: 'Description',
    disabled: 'Disabled',
    docNumber: 'Document Number',
    draft: 'Draft',
    email: 'Email',
    emailAddress: 'Email address',
    enabled: 'Enabled',
    endDate: 'To',
    expirationDate: 'End date of validity',
    familyName: 'Last name',
    from: 'Since',
    fromDate: 'Since',
    function: 'Function',
    givenName: 'Name',
    hasExpiredOffer: 'Expired offer',
    inactive: 'Disabled',
    invoiceNumber: 'Invoice number',
    isEnabled: 'Active',
    isVerified: 'Verified',
    issuedAt: 'Document Date',
    labSampleCode: 'Sample code',
    lot: 'Batch',
    method: 'Method',
    methodCode: 'Method code',
    name: 'Name',
    newsTitle: 'News title',
    offer: 'Offer',
    offerEnabled: 'Active',
    offerNumber: 'Offer number',
    ondaOrderId: 'Id ONDA',
    order: 'Order',
    orderConfirmId: 'Order confirmation number',
    orderId: 'Order ID',
    orderLocked: 'Blocked order',
    orderName: 'Order Name',
    orderNumber: 'Order Number',
    ordersSent: 'Sent',
    phone: 'Phone',
    price: 'Amount',
    productName: 'Product Name',
    publishedAt: 'Publication date',
    quantity: 'Quantity',
    rejectReason: 'Reason for refusal',
    remaining: 'Remaining',
    reportsList: 'Report list',
    revisionDate: 'Revision date',
    revisionNumber: 'Revision number',
    role: 'Role',
    sampleCount: 'Number of Samples',
    sampleId: 'Sample ID',
    sampleReportCodes: 'Test report codes',
    sector: 'Sector',
    sent: 'Sent',
    sentAt: 'Order submission date',
    startDate: 'Since',
    status: 'Status',
    testType: 'Test type',
    title: 'Order Name',
    toDate: 'To',
    type: 'Type',
    user: 'User',
    vat: 'VAT number',
    zipCode: 'Postal code',
  },
  global: {
    add: 'Add',
    apply: 'Apply',
    attachment: 'Attachment',
    back: 'Back',
    backToList: 'Back to list',
    browse: 'Browse',
    cancel: 'Cancel',
    confirmUploadCancel: 'Are you sure you want to remove the file?',
    connectionError: 'Communication error with the server!',
    continue: 'Continue',
    downloadPdf: 'Download PDF',
    edit: 'Edit',
    english: 'English',
    fileExceeded: 'Upload a file of < {0} Mb in size',
    help: 'Help',
    italian: 'Italian',
    lang: 'Language',
    noMultipleFilesUpload: 'you must remove the previous file before you can upload a new one!',
    notCsvFile: 'Please upload a valid csv file!',
    notPdfFile: 'Please upload a valid pdf file!',
    removeSwitchPdf: 'Replace/Remove PDF',
    requiredFields: 'Fields marked with * are mandatory',
    select: 'Select',
    spanish: 'Spanish',
    uploadFilesLimitExceeded: 'The maximum number of files that can be uploaded has been reached',
    uploadPdf: 'Upload PDF',
    warning: 'Warning!',
  },
  header: {
    titles: {
      analysesIndex: 'Analysis list',
      analysisCreate: 'Create Analysis/Class',
      analysisEdit: 'Edit Analysis',
      customersEdit: 'Edit company',
      customersIndex: 'Companies',
      editOffer: 'Edit Offer',
      editOrder: 'Modify Order',
      editOrderSample: 'Edit Sample',
      newOffer: 'Create Offer',
      offersIndex: 'Offers list',
      ordersIndex: 'Order List',
      showOffer: 'Offer',
      usersIndex: 'Users list',
    },
    welcome: 'Welcome',
  },
  loginView: {
    accessButton: 'Login',
    passwordForgotLabel: 'Forgot Password?',
    passwordInput: 'Passwords',
    title: 'Yours',
    titleBlue: 'Laboratory',
    usernameInput: 'Username',
  },
  logout: {
    label: 'Log out',
    successMessage: 'Successfully logged out',
  },
  menuItems: {
    analysesIndex: 'Analyses',
    customersIndex: 'Companies',
    invoicesIndex: 'Invoices',
    newsAdminIndex: 'News',
    newsUserIndex: 'News',
    offersIndex: 'Offers',
    ordersIndex: 'Orders',
    requestOffer: 'Request offer',
    samplesIndex: 'Samples status',
    usersIndex: 'Users',
  },
  news: {
    create: {
      abstract: 'Abstract text',
      attachments: 'Upload attachments',
      createNews: 'Create news',
      draftSubmitButton: 'Save to draft',
      formHeader: 'Create news',
      image: 'Upload featured image',
      imagePlaceholder: 'Upload a 1200 x 400 image',
      isPublished: 'Posted',
      publishedAt: 'Publication date',
      submitButton: 'Save and publish news',
      success: 'News created successfully',
      text: 'News text',
      title: 'News title',
    },
    delete: {
      cancelled: 'Deletion canceled!',
      confirm: 'Are you sure you want to delete the news?',
      success: 'News deleted!',
    },
    detail: {
      attachments: 'Attached documents',
      title: 'News detail',
    },
    index: {
      noRecords: 'There are no news',
    },
    update: {
      bulkSuccess: 'Successfully modified news',
      draftSubmitButton: 'Put in draft',
      formHeader: 'Edit news',
      submitButton: 'Edit news',
      success: 'News successfully modified',
    },
  },
  offer: {
    comment: 'Comment',
    create: {
      formHeader: 'Create Offer',
      request: 'Do you want to request an offer?',
      requestSubtitle: 'Fill in the fields below',
    },
    createError: 'An error occurred while creating the offer!',
    customer: 'Company',
    docNumber: 'Document Number',
    downloadPdf: 'Download PDF',
    editSuccess: 'Offer modified successfully!',
    expirationDate: 'End date of validity',
    help: {
      field: {
        admin: {
          docNumber: 'Form document number info',
        },
      },
      filter: {
        admin: {
          docNumber: 'Document number info',
        },
      },
    },
    index: {
      createOffer: 'Create Offer',
    },
    issuedAt: 'Document Date',
    ondaOfferId: 'ONDA Document ID',
    ourReference: 'Our reference',
    pdfFileId: 'Upload PDF',
    request: {
      marketingConsentText1: 'You authorize us to contact you and send you by email information about our services/products/events and promotions that may interest you.',
      marketingConsentText2: 'Authorization to process data for this purpose is not necessary, but you would risk missing out on something that might interest you.',
      message: 'Message',
      offer: 'Previous offer number',
      previousOfferNotFind: 'Previous offer not found',
      privacyConsent: ' ',
      privacyConsentLabel: 'Processing of personal data',
      privacyConsentText: 'I have read and accept the information relating to the Processing of Personal Data pursuant to EU Regulation 2016/679 arts. 13 and 14',
      success: 'Request entered successfully!',
    },
    revisionDate: 'Revision date',
    revisionNumber: 'Offer revision number',
    status: 'Status',
    update: {
      formHeader: 'Edit Offer',
      success: 'Offer successfully modified',
    },
    uploadCsvFile: 'Upload excel file',
    yourReference: 'Your reference',
  },
  orders: {
    backWithoutSaving: 'Back to orders',
    create: {
      abortCreate: 'Cancel',
      cancelled: 'Operation cancelled',
      confirmCancel: 'You are about to exit the order edit. Are you sure you want to continue without saving your current changes?',
      confirmSend: 'You are about to exit the order edit. Are you sure you want to go ahead and save the current order?',
      createOrder: 'Create Order',
      customer: 'Company name',
      customerId: 'Company name',
      customerOrderNumber: 'Order Number',
      saved: 'Order saved successfully',
      sent: 'Order with code {orderId} sent successfully',
      separateInvoice: 'Do you want this order to be invoiced separately?',
      separateInvoiceNo: 'No',
      separateInvoiceYes: 'Yes',
      success: 'Order created successfully',
      title: 'Order Name',
    },
    delete: {
      cancelled: 'Cancelled deletion',
      confirm: 'Do you want to delete the order?',
      success: 'Order deleted',
    },
    goToSummary: 'Go to the summary',
    orderSamples: 'Samples',
    print: 'Print',
    samples: {
      abortUpload: 'Cancel',
      confirmUpload: 'Are you sure you want to continue? The previously uploaded data will be overwritten.',
      importError: 'Import error, check that the file is in the correct format',
      importSuccess: 'Successfully imported',
      uploadReport: 'Upload report',
      uploadReports: 'Submit',
    },
    saveForLater: 'Save for later',
    send: 'Submit',
    update: {
      success: 'Order updated successfully',
      updateOrder: 'Update',
    },
    warning: {
      confirm: 'Yes',
      expired: 'Expired offer',
      message: 'The offer has expired, do you want to request a new one?',
      reject: 'No',
      success: 'OK',
    },
  },
  paneContent: {
    testLabel: 'Try out',
  },
  passwordRecoverView: {
    emailInput: 'Email',
    passwordRecoveredMessage: 'Further instructions have been sent to your email address',
    submitButton: 'Submit',
    title: 'Recover your password',
  },
  passwordResetView: {
    confirmPasswordInput: 'Confirm password',
    passwordChangedMessage: 'Password changed successfully',
    passwordInput: 'Password',
    submitButton: 'Submit',
    title: 'Please fill in your new password',
  },
  permissions: {
    admin: 'Super Admin',
    billing: 'Invoice',
    billingManage: 'Edit',
    billingView: 'View',
    customers: 'Companies',
    customersManage: 'Edit',
    customersView: 'View',
    news: 'News',
    newsManage: 'Edit',
    newsView: 'View',
    offers: 'Offers',
    offersManage: 'Edit',
    offersView: 'View',
    orders: 'Orders',
    ordersManage: 'Edit',
    ordersView: 'View',
    samplesStatus: 'Samples status',
    samplesStatusManage: 'Edit',
    samplesStatusView: 'View',
    title: 'Permissions',
    users: 'Users',
    usersManage: 'Edit',
    usersView: 'View',
  },
  samples: {
    clone: {
      confirm: 'Are you sure you want to duplicate the sample?',
      success: 'Duplicate sample',
    },
    create: {
      abortCreate: 'Cancel',
      actions: 'Actions',
      address: {
        addNew: 'Add New',
        address: 'Address',
        city: 'City',
        name: 'Company name',
        phoneNumber: 'Phone number',
        province: 'Province',
        zip: 'Postal code',
      },
      comments: 'Comments',
      costCenter: 'Customer Cost Center',
      createSample: 'Add Sample',
      customerAddress: 'Customer address',
      customerHeaderData: 'Do you want to insert final customer data in the header of the test report?',
      customerHeaderDataNo: 'No',
      customerHeaderDataYes: 'Yes',
      customerProductName: 'Customer sample code',
      date: 'Batch date',
      downloadExample: 'Download XLSX',
      fileSize: 'PDF files smaller than 10 MB',
      finalCustomer: 'Final Customer',
      finalCustomerRef: 'Final Customer Reference',
      import: 'XLSX bulk load',
      labSampleCode: 'Product Code',
      lot: 'Batch',
      notes: 'Client notes',
      packagesNumber: 'No. of Samples',
      productName: 'Product Name',
      sampleDescription: 'Sample Description',
      sector: 'Sector',
      success: 'Successfully created sample',
    },
    delete: {
      cancelled: 'Cancellation stopped!',
      confirm: 'Are you sure you want to delete the sample?',
      success: 'Cancelled sample!',
    },
    sampleDescriptions: {
      anhydrousFluid: 'Anhydrous fluid',
      anhydrousPaste: 'Anhydrous paste',
      biphasic: 'Biphasic',
      capsule: 'Capsule',
      emulsion: 'Emulsion',
      liquid: 'Liquid',
      liquidGel: 'Liquid gel',
      loosePowder: 'Loose dust',
      oil: 'Oil',
      packaging: 'Packaging',
      patch: 'Patch',
      pressedPowder: 'Pressed powder',
      sanitaryTowelsDiapers: 'Sanitary towels - Diapers',
      tablet: 'Tablet',
      validation: 'Validations',
      wipes: 'Wipes',
    },
    sectors: {
      biocide: 'Biocide',
      chemical: 'Chemical',
      cosmetic: 'Cosmetic',
      detergents: 'Detergents',
      foodSupplement: 'Food supplements for human use',
      medicalDevice: 'Medical device',
      other: 'Other',
      packaging: 'Packaging',
      pharmaceutical: 'Pharmaceutical',
      nonSterile: 'Non-Sterile product',
      animalWellness: 'Animal wellness products',
    },
    showReportsList: 'Show all reports',
    update: {
      confirm: 'Are you sure you want to modify the sample?',
      success: 'Successfully updated sample',
      updateSample: 'Update Sample',
      waitApproval: 'To modify this sample, you must wait for the administrator\'s approval.',
    },
  },
  table: {
    massiveActions: {
      submit: 'Apply',
      title: 'Apply status to everyone',
    },
  },
  tableButtons: {
    addAnalyses: 'Add analyses',
    addAnalysisToClass: 'Add to a class',
    change: 'Edit',
    changePdf: 'Replace PDF',
    cloneSample: 'Duplicate Sample',
    completeOrder: 'Finalize Order',
    deleteNews: 'Delete news',
    deleteOrder: 'Delete order',
    deleteSample: 'Delete Sample',
    disable: 'Disable',
    disableAnalysis: 'Disable',
    disableCustomer: 'Disable',
    disableRawData: 'Disable raw data',
    disableSelected: 'Disable selected',
    disableUser: 'Disable',
    downloadExcel: 'Download Excel',
    downloadOffer: 'Download offer',
    downloadSampleReport: 'Download report',
    editArticle: 'Edit item',
    editCustomer: 'Edit',
    editNews: 'Edit news',
    editOrder: 'Modify Order',
    editSample: 'Edit Sample',
    editUser: 'Edit',
    enable: 'Enable',
    enableAnalysis: 'Enable',
    enableCustomer: 'Enable',
    enableRawData: 'Enable raw data',
    enableUser: 'Enable',
    exportExcel: 'Download Excel',
    exportRawData: 'Download raw data',
    reloadExcel: 'Reload Excel',
    renameSample: 'Rename',
    seeDetail: 'View',
    selectOnlyThisSampleOff: 'View all analyses',
    selectOnlyThisSampleOn: 'Show associated analyses only',
    sendVerificationEmail: 'Send verification email',
    showAnalyses: 'Show analyses',
    showAttachment: 'Open attachment',
    showInvoice: 'Open Invoice',
    showOrderSamples: 'Show samples',
    showSamples: 'View samples',
    showUsers: 'View all users',
    uploadPdf: 'Upload PDF',
    uploadSampleReport: 'Upload PDF',
    userOrders: 'Orders',
  },
  tooltips: {
    analysesIndex: 'On this screen you can monitor the analysis status of your sample',
    customerOrderNumber: 'Enter your purchase order number, so that it appears correctly on the invoice',
    invoicesIndex: 'Invoices associated with my company',
    massiveXlsOrder: 'Compile the Excel file following the instructions you find inside it and use it to load multiple samples at the same time',
    newSample: {
      comments: 'Additional notes (will not be present in the Test Report)',
      costCenter: 'Customer Cost Center',
      customerSampleCode: 'Customer sample code',
      description: 'Description of the sample (select from the list)',
      finalCustomer: 'Final customer who commissioned the sample',
      finalCustomerRef: 'Ref. of the final customer who ordered the sample',
      labSampleCode: 'Sample item code',
      lot: 'Sample batch',
      lotDate: 'Batch production date',
      lotNumber: 'Number of samples (required)',
      note: 'Customer notes (present in the Test Report)',
      pdfUpload: 'Upload the files containing all the documents that you want to communicate to complife for a correct execution of the test (e.g. specifications, technical data sheet), or that are required for the execution of the test (e.g. INCI)',
      productName: 'Sample name (required)',
      sector: 'Product sector of the sample (mandatory - select from the list)',
    },
    offersIndex: 'List of valid offers',
    orderName: 'Give your order a name',
    ordersIndex: 'On this screen you can place and monitor your orders',
    remainingAnalyses: 'Number of tests remaining. Valid only for Subscriptions rates and Volume discount offers',
    requestOffer: 'On this view you can request the issuance of an expired offer or for a new type of test',
    samplesIndex: 'On this screen you can monitor your samples and download the results',
  },
  users: {
    create: {
      abortCreate: 'Cancel',
      createUser: 'Create User',
      customer: 'Company name',
      email: 'Email',
      emailConfirmation: 'Confirm Email',
      familyName: 'Last name',
      function: 'Function',
      functions: {
        administration: 'Administration',
        logistics: 'Logistics',
        production: 'Manufacturing',
        purOff: 'Purchasing Department',
        qa: 'Quality Assurance',
        qc: 'Quality Control',
        rd: 'Research and Development',
      },
      givenName: 'Name',
      password: 'Password',
      passwordConfirmation: 'Confirm password',
      phone: 'Phone',
      role: 'Role',
      success: 'User successfully created',
    },
    update: {
      invitationSent: 'Verification email sent',
      success: 'User Updated Successfully',
      updateUser: 'Edit user',
    },
  },
};
